import Image from 'next/image';
import linePattern from '../../public/images/line-pattern.png';
//import signupBg from '../../public/images/signup-bg.png';

export default function SignupRight() {
  return (
    <div className="w-full h-[100vh] relative overflow-hidden">
      <Image
        src={linePattern}
        className="absolute top-0 right-0 w-1/3"
        alt={''}
      />
      <Image
        src={linePattern}
        className="absolute left-0 bottom-0 w-1/3"
        alt={''}
      />
      {/*       <Image
        src={signupBg}
        className="absolute right-0 left-[160px] top-0 bottom-0 m-auto max-h-[80%] object-contain rounded-lg shadow-xl object-left"
        alt={''}
      /> */}
    </div>
  );
}
