import { HTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;
export function HStack({ children, className, ...props }: Props) {
  return (
    <div {...props} className={twMerge('flex items-center', className)}>
      {children}
    </div>
  );
}
