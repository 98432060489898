import { HStack } from '../components/common/HStack';
import { Logo } from '../components/common/Logo';
//import { Text } from '../components/common/Text';
import { VStack } from '../components/common/VStack';
//import Card from '../components/design-system/Card';
import AnimationLayoutWrapper from './AnimationLayoutWrapper';
interface AuthenticationProps {
  leftForm: React.ReactNode;
  formTitle?: string | React.ReactNode;
  formText?: string | React.ReactNode;
  topAction?: React.ReactNode;
  right: React.ReactNode;
}
export default function Authentication({
  leftForm,
  /*   right,
  formTitle,
  formText, */
  topAction,
}: AuthenticationProps) {
  return (
    <AnimationLayoutWrapper>
      <HStack className="min-h-[100vh] bg-white w-full">
        <div className="justify-center items-center w-[100%] flex min-h-[100vh]">
          <VStack className="w-full max-w-lg px-4 py-8 space-y-6 h-full justify-center relative">
            <div className="absolute top-8">{topAction}</div>
            <div className="flex justify-center w-full">
              {/* Adjusting the Logo container to ensure it's centered */}
              <div className="flex justify-center w-full">
                <Logo variant={'logintext'} className="w-[400px]" />
              </div>
            </div>
            {/*             <Card className="shadow-sm w-full drop-shadow-lg"> */}
            {/*             <div>
              <div className="flex flex-col gap-4">
                <Text className="text-4xl font-semibold">{formTitle}</Text>
                <Text className="text-md text-gray-500">{formText}</Text>
              </div>
            </div> */}
            {leftForm}
            {/*             </Card> */}
          </VStack>
        </div>
        {/*         <div className="bg-slate-200 min-h-[100vh] w-[55%] shadow-inner">
          {right}
        </div> */}
      </HStack>
    </AnimationLayoutWrapper>
  );
}
