import React from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import IconButton from '../basics/IconButton';
import TextInput, { TextInputProps } from './TextInput';

type PasswordInputProps = Omit<TextInputProps, 'type'>;

export default function PasswordInput(props: PasswordInputProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <TextInput
      {...props}
      type={isOpen ? 'text' : 'password'}
      rightIcon={
        <IconButton
          variant="no-padding"
          aria-label={isOpen ? 'Mask password' : 'Reveal password'}
          icon={
            isOpen ? (
              <HiEyeOff className="text-gray-500" size="18" />
            ) : (
              <HiEye className="text-gray-500" size="18" />
            )
          }
          onClick={() => setIsOpen(!isOpen)}
        />
      }
    />
  );
}
