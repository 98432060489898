import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends PropsWithChildren {
  className?: string;
  title?: string;
  variant: 'error' | 'info' | 'warning' | 'success';
}

export function Alert({ children, className, title, variant }: Props) {
  const titleColor =
    variant === 'error'
      ? 'text-red-800'
      : variant === 'warning'
      ? 'text-yellow-800'
      : variant === 'info'
      ? 'text-blue-800'
      : 'text-green-800';
  const childrenColor =
    variant === 'error'
      ? 'text-red-700'
      : variant === 'warning'
      ? 'text-yellow-700'
      : variant === 'info'
      ? 'text-blue-700'
      : 'text-green-700';
  const bgColor =
    variant === 'error'
      ? 'bg-red-50'
      : variant === 'warning'
      ? 'bg-yellow-50'
      : variant === 'info'
      ? 'bg-blue-50'
      : 'bg-green-50';
  return (
    <div className={twMerge('rounded-md px-4 py-2', bgColor, className)}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          {variant === 'warning' && (
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          )}
          {variant === 'error' && (
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          )}
          {variant === 'success' && (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">
          <h3 className={twMerge('text-md font-medium', titleColor)}>
            {title}
          </h3>
          {children && (
            <div className={twMerge('mt-2 text-md', childrenColor)}>
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
