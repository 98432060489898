import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import { ChangeEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';
import { useThemeConfig } from '../../../lib/state/GlobalStateContext';

export interface TextInputProps {
  label: string;
  name: string;
  type:
    | 'email'
    | 'password'
    | 'text'
    | 'number'
    | 'tel'
    | 'url'
    | 'search'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week'
    | 'currency'
    | 'textarea';
  value?: string;
  placeholder?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  classNames?: string[];
  size?: 'xs' | 'sm' | 'md' | 'lg';
  leftIcon?: React.ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  rightIcon?: React.ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  containerClassName?: string;
  disabled?: boolean;
  rows?: number;
}

export default function TextInput({
  isInvalid = false,
  classNames = [],
  label,
  name,
  type,
  value,
  placeholder,
  errorMessage,
  size = 'sm',
  leftIcon,
  onChange,
  rightIcon,
  onFocus,
  onBlur,
  containerClassName,
  disabled = false,
  rows = 3,
}: TextInputProps) {
  const theme = useThemeConfig();

  const inputClasses = classnames(
    'block bg-white w-full rounded-lg border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset text-md indent-4 outline-none',
    isInvalid
      ? 'text-error-900 ring-error-300 placeholder:text-error-300 focus:ring-error-500'
      : twMerge(
          'text-slate-900 ring-slate-300 placeholder:text-slate-300',
          theme.inputFocusRing
        ),
    size === 'sm'
      ? 'h-10'
      : size === 'md'
      ? 'h-12'
      : size === 'xs'
      ? 'h-8'
      : 'h-14',
    ...classNames,
    disabled ? 'bg-gray-100 cursor-not-allowed' : ''
  );

  const renderInput = () => {
    if (type === 'textarea') {
      return (
        <textarea
          name={name}
          id={'input' + name}
          className={inputClasses}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          rows={rows}
        />
      );
    } else {
      return (
        <input
          type={type}
          name={name}
          id={'input' + name}
          className={inputClasses}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
    }
  };

  return (
    <div className={twMerge('w-full bg-white', containerClassName)}>
      <label
        htmlFor={name}
        className="block text-md font-medium leading-6 text-gray-700"
      >
        {label}
      </label>
      <div
        className={twMerge(
          'relative mt-2 rounded-md shadow-sm',
          label === '' ? 'mt-0' : ''
        )}
      >
        {leftIcon && (
          <div className="absolute left-0 top-0 bottom-0 my-auto cursor-default items-center flex">
            {leftIcon}
          </div>
        )}
        {renderInput()}
        {isInvalid && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-error-500"
              aria-hidden="true"
            />
          </div>
        )}
        {rightIcon && !isInvalid && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {rightIcon}
          </div>
        )}
      </div>
      {isInvalid && (
        <p className="mt-2 text-md text-error-600 animate-in fade-in">
          {errorMessage}
        </p>
      )}
    </div>
  );
}
