// A tailwind styled button with an icon

// Path: packages\web\components\common\forms\TextInput.tsx

import classnames from 'classnames';

interface Props {
  icon: React.ReactNode;
  classNames?: string[];
  variant?: 'no-padding' | 'padding' | 'hover';
  onClick?: () => void;
  disabled?: boolean;
}

export default function IconButton({
  icon,
  classNames = [],
  onClick,
  variant,
  disabled = false,
}: Props) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={classnames(
        variant === 'padding' ? 'px-2 py-1.5 ' : '',
        'rounded text-base font-semibold text-slate-700 border border-transparent  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer',
        variant === 'hover' &&
          ' hover:shadow-md hover:border-gray-500 transition-all px-1 py-0.5',
        'transition-colors',
        disabled && 'opacity-50 cursor-not-allowed',
        ...classNames
      )}
    >
      {icon}
    </button>
  );
}
