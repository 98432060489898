import { FormikErrors, useFormik } from 'formik';
//import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import validator from 'validator';
//import { GoogleIcon } from '../components/authentication/ProviderIcons';
//import { Divider } from '../components/common/Divider';
import { HStack } from '../components/common/HStack';
//import { Logo } from '../components/common/Logo';
//import { Text } from '../components/common/Text';
import SignupRight from '../components/authentication/SignupRight';
import { VStack } from '../components/common/VStack';
import IntrepidButton from '../components/common/basics/Button';
import PasswordInput from '../components/common/forms/PasswordInput';
import TextInput from '../components/common/forms/TextInput';
import { Alert } from '../components/design-system/Alert';
//import Card from '../components/design-system/Card';

import { useTitle } from '../hooks/useTitle';
import Authentication from '../layouts/Authentication';
import ApiClient from '../lib/api/ApiClient';
import { useAuth } from '../lib/auth/AuthContextProvider';

interface FormValues {
  email: string;
  password: string;
}

export const Login = () => {
  const [error, setError] = React.useState<string | null>(null);
  const router = useRouter();
  const auth = useAuth();
  useTitle('Superstar | Login');

  const { values, handleChange, errors, touched, handleSubmit, isSubmitting } =
    useFormik({
      initialValues: { email: '', password: '' },
      onSubmit: async (values, { setSubmitting }) => {
        sessionStorage.clear();
        setError(null);
        await ApiClient.post('/v1/users/login', {
          email: values.email,
          password: values.password,
        })
          .then(async () => {
            await auth.refreshFromProfile();

            router.push('/home');
          })
          .catch((error) => {
            if (
              error.response.data.message ==
              'Email ownership must be verified before authentication.'
            ) {
              router.push(
                `/signup/verifyemail?email=${encodeURIComponent(values.email)}`
              );
            } else if (
              error.response.data.message ==
              'The user must enroll in MFA to finish authenticating.'
            ) {
              const pendingAuthToken =
                error.response.headers['x-pending-auth-token'];
              if (pendingAuthToken) {
                // Store the token for use in the MFA setup page
                sessionStorage.setItem('pendingAuthToken', pendingAuthToken);
              }

              sessionStorage.setItem('userEmail', values.email);
              router.push(`/signup/setupMFA`);
            } else if (
              error.response.data.message ==
              'The user must complete an MFA challenge to finish authenticating.'
            ) {
              const pendingAuthToken =
                error.response.headers['x-pending-auth-token'];
              if (pendingAuthToken) {
                // Store the token for use in the MFA setup page
                sessionStorage.setItem('pendingAuthToken', pendingAuthToken);
              }

              const authenticationFactors =
                error.response.headers['x-authentication-factors'];

              sessionStorage.setItem('userEmail', values.email);
              sessionStorage.setItem(
                'authenticationFactors',
                authenticationFactors
              );

              router.push(`/verifyMFA`);
            } else {
              setError(error.response.data.message);
            }
          });
        // TODO: For Email not confirmed, we should allow resending email confirmation
        setSubmitting(false);
      },
      validate: (values) => {
        const errors: FormikErrors<FormValues> = {};

        validator.isEmail(values.email) ||
          (errors.email = 'Invalid email address');
        !validator.isEmpty(values.password) ||
          (errors.password = 'Password is required');
        return errors;
      },
    });

  return (
    <Authentication
      leftForm={
        <VStack className="gap-2">
          <VStack className="gap-4 w-full">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <VStack className="gap-4">
                <VStack className="gap-4">
                  <TextInput
                    label={'Email'}
                    name={'email'}
                    type={'text'}
                    isInvalid={!!errors.email && touched.email}
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    errorMessage={errors.email}
                  />

                  <PasswordInput
                    onChange={handleChange}
                    name="password"
                    value={values.password}
                    placeholder="••••••••"
                    isInvalid={!!errors.password && touched.password}
                    errorMessage={errors.password}
                    label={'Password'}
                  />
                </VStack>
                <HStack className=" justify-end">
                  <IntrepidButton
                    variant="link"
                    size="md"
                    onClick={() => {
                      router.push('/signup/resetpassword');
                    }}
                  >
                    Forgot password?
                  </IntrepidButton>
                </HStack>
                {error && <Alert variant="error" title={error} />}
                <IntrepidButton
                  type="submit"
                  variant="style"
                  isLoading={isSubmitting}
                >
                  Sign In
                </IntrepidButton>
              </VStack>
            </form>

            <VStack className="gap-4 items-center">
              {/* //TODO: Add Google Oauth  */}
              {/*               <HStack className="gap-2">
                <Divider className="border-gray-300" />
                <Text className="text-gray-600">OR</Text>
                <Divider className="border-gray-300" />
              </HStack> */}
              {/*               <Link
                href={
                  new URL(
                    '/v1/users/google-oauth',
                    process.env.NEXT_PUBLIC_API_URL
                  ).href
                }
              >
                <IntrepidButton variant="outline" classNames={['w-full']}>
                  <HStack className="gap-2 justify-center">
                    <GoogleIcon />
                    <span>Continue with Google</span>
                  </HStack>
                </IntrepidButton>
              </Link> */}
              {/* <OAuthButtonGroup /> */}
            </VStack>
            <VStack>
              <HStack className="justify-center gap-2">
                <span className={'text-gray-500 text-md'}>
                  Don&apos;t have an account?
                </span>
                <IntrepidButton
                  onClick={() => router.push('/signup')}
                  variant="link"
                  size="md"
                >
                  Sign up
                </IntrepidButton>
              </HStack>
            </VStack>
            <div className="text-gray-500">
              This site is protected by hCaptcha and its{' '}
              <a
                target={'_blank'}
                rel="noreferrer"
                className="text-primary-600"
                href="https://www.hcaptcha.com/privacy"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                target={'_blank'}
                rel="noreferrer"
                className="text-primary-600"
                href="https://www.hcaptcha.com/terms"
              >
                Terms
              </a>{' '}
              apply.
            </div>
          </VStack>
        </VStack>
      }
      right={<SignupRight />}
    />
  );
};

export default Login;
