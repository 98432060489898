import { motion, Variants } from 'framer-motion';
import { PropsWithChildren } from 'react';
import { PageAnimationFadeInOut } from '../animates/animates';

interface Props extends PropsWithChildren {
  variantsOverride?: Variants;
}

const AnimationLayoutWrapper = ({ children, variantsOverride }: Props) => (
  <motion.div
    initial="initial"
    animate="enter"
    exit="exit"
    variants={variantsOverride || PageAnimationFadeInOut}
  >
    {children}
  </motion.div>
);
export default AnimationLayoutWrapper;
